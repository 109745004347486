/*
 * 업무구분: 고객
 * 화 면 명: MSPFS110M
 * 화면설명: 설문등록7005
 * 작 성 일: 2023.04.24
 * 작 성 자: 이태흥
 */
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">

    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top gap40">
          <div class="left column flex1 mr0">
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label class="emphasis"> 실명번호 종류 </label>
                <mo-dropdown 
                  ref="cob_rname_gb"
                  :items="lv_commCode.code211"
                  v-model="lv_cob_rname_gb.value" 
                  :disabled="lv_cob_rname_gb.disabled"
                />
              </div>
              <div class="wrap-input row">
                <label class="emphasis"> 실명번호 </label>
                <mo-text-field 
                  ref="ed_rname_no1"
                  class="w80"
                  :class="lv_ed_rname_no.error ? 'error' : ''"
                  v-model="lv_ed_rname_no.value1"
                  :disabled="lv_ed_rname_no.disabled"
                  type="number"
                  maxlength="6"
                  placeholder="앞 6자리" 
                  @keyup="fn_SearchValid($event, 0)"
                />
                <m-trans-key-input
                  v-if="isMobile && isMtrans"
                  ref="ed_rname_no2"
                  class="w80"
                  :class="lv_ed_rname_no.error ? 'error' : ''"
                  v-model="lv_ed_rname_no.value2"
                  type="numberMax7"
                  dialog='Y'
                  start="1" 
                  end="-1"    
                  :isClear="lv_isClear"
                  @masked-txt="fn_SetMaskedTxt"
                  :disabled="lv_ed_rname_no.disabled"
                />
                <mo-text-field
                  v-else 
                  ref="ed_rname_no2"
                  class="w80"
                  type="password"
                  :class="lv_ed_rname_no.error ? 'error' : ''"
                  v-model="lv_ed_rname_no.value2"
                  :disabled="lv_ed_rname_no.disabled"
                  maxlength="7"
                  placeholder="뒤 7자리" 
                  @keyup="fn_SearchValid($event, 0)"
                />
              </div>
            </div>
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label class="emphasis"> 고객번호 </label>
                <mo-text-field 
                  ref="ed_cust_no"
                  class="w130 flex-unset"
                  :class="lv_ed_cust_no.error ? 'error' : ''"                  
                  v-model="lv_ed_cust_no.value" 
                  maxlength="7"
                  type="number"
                  :disabled="lv_ed_cust_no.disabled"                  
                  @keyup="fn_SearchValid($event, 1)"
                />
                <mo-button class="w61" @click="fn_OpenPopup(0)"> 고객정보 </mo-button>
                <mo-text-field 
                  class="w130 flex-unset" 
                  v-model="lv_ed_cust_nm.value" 
                  disabled
                />
              </div>
            </div>
            <div class="row w100p">
              <div class="wrap-input row mw-auto">
                <label class="emphasis"> 전문투자자여부 </label>
                <!-- <mo-radio 
                  v-model="lv_tlgm_ivt_peson_yn.value" 
                  class="flex-unset mw-auto mr14"
                > 일반금융소비자 </mo-radio>
                <mo-radio 
                  class="flex-unset mw-auto"
                  disabled
                > 전문금융소비자 </mo-radio> -->

                <mo-radio-wrapper 
                  ref="tlgm_ivt_peson_yn"
                  :items="tlgmIvtPesonYn" 
                  v-model="lv_tlgm_ivt_peson_yn.value" 
                  class="row"
                  :class="lv_tlgm_ivt_peson_yn.error ? 'error' : ''"
                  disabled
                />
              </div>
            </div>
            <div class="row w100p">
              <div class="wrap-input row mw-auto">
                <label class="emphasis"> 투자권유희망여부 </label>
                <mo-radio-wrapper 
                  ref="ivt_cnvs_hop_yn"
                  :items="ivtCnvsHopYn" 
                  v-model="lv_ivt_cnvs_hop_yn.value" 
                  class="row"
                  :class="lv_ivt_cnvs_hop_yn.error ? 'error' : ''"
                  :disabled="lv_ivt_cnvs_hop_yn.disabled"
                  @input="fn_clear('ivtCnvs')"                  
                />
              </div>
            </div>
            <div class="row w100p">
              <div class="wrap-input row mw-auto">
                <label class="emphasis"> 유효기간 설정여부 </label>
                <mo-radio-wrapper 
                  ref="qstn_expd_yys"
                  :items="qstnExpdYys" 
                  v-model="lv_qstn_expd_yys.value" 
                  class="row"
                  :class="lv_qstn_expd_yys.error ? 'error' : ''"
                  :disabled="lv_qstn_expd_yys.disabled"
                  @input="fn_clear('qstnExpd')" 
                />
              </div>
              <div class="wrap-input row">
                <label> 유효기간 </label>
                <!-- <span class="gray readonly">2023.03.06 ~ 2025.03.05</span> -->
                <span class="gray readonly">{{ lv_expd_ymd_text }}</span>
              </div>
            </div>
          </div>
          <div class="right column gap10 flex1">
            <div class="row w100p">
              <div class="wrap-input row">
                <label class="emphasis"> 성명 </label>
                <mo-text-field 
                  class="w130" 
                  v-model="lv_ed_rname_nm.value"
                  :disabled="lv_ed_rname_nm.disabled"
                />
              </div>
            </div>
            <div class="row w100p">
              <div class="wrap-input row mw-auto">
                <label class="emphasis"> 재진단사유 </label>
                <mo-radio-wrapper 
                  :items="radReqstn" 
                  v-model="lv_rad_reqstn_rsn.value" 
                  class="row"
                  :class="lv_rad_reqstn_rsn.error ? 'error' : ''"
                  :disabled="lv_rad_reqstn_rsn.disabled"
                  @input="fn_clear('radReqstn')"
                />
              </div>
            </div>
            <div class="row w100p">
              <div class="wrap-input row mw-auto">
                <label class="emphasis"> 전문투자자 효력기간 </label>
                <mo-range-picker 
                  class="picker-w110" 
                  :class="lv_pro_invest_fromTo.error ? 'error' : ''"
                  v-model="lv_pro_invest_fromTo.value" 
                  :bottom="false" 
                  :disabled="lv_pro_invest_fromTo.disabled"
                />
              </div>
            </div>
            <div class="row w100p h28"></div>
            <div class="row w100p justify-end">
              <div class="wrap-button row">
                <mo-button class="clear" @click="fn_Init()"> 초기화 </mo-button>
                <mo-button primary 
                  class="inquiry" 
                  @click="fn_SearchValid()"
                  :disabled="lv_insert_ok == 'Y' ? true : false"                  
                > 조회 </mo-button>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div 
          class="wrap-desc-02"
          :class="lv_isList ? 'disabled' : ''"
        >
          <h2>투자자정보 확인서</h2>
          <p>
            아래의 질문사항은 고객님의 투자성향을 분석하기 위한 자료로써 고객님의 투자목적, 재정상태, 투자성향 등에 대한 답변을 종합적으로 검토하여 투자성향을 파악하기 위한 
            설문지 입니다. 다음 질문에 대하여 가장 적절하다고 생각되는 답을 체크하여 주시기 바랍니다.
          </p>
        </div>
        <div class="wrap-table table-radio-02">
          <table class="table col-type">
            <tbody>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>1.</i> 고객님의 연령대는 어떻게 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn1" 
                    :items="qstn1" 
                    v-model="lv_qstn1_sel.value" 
                    class="column"
                    :class="lv_qstn1_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn1')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>2.</i> 다음 중 고객님의 투자 경험과 가장 가까운 것은 어느 것입니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn2"  
                    :items="qstn2" 
                    v-model="lv_qstn2_sel.value" 
                    class="column"
                    :class="lv_qstn2_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn2')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>3.</i> 고객님께서는 금융상품 투자에 대한 본인의 수준이 어느 정도라고 생각하십니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn3" 
                    :items="qstn3" 
                    v-model="lv_qstn3_sel.value" 
                    class="column"
                    :class="lv_qstn3_sel.error ? 'error' : ''"
                    :disabled="lv_isList || lv_qstn3_sel.disabled"
                    @input="fn_clear('qstn3')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>4.</i> 고객님께서 보유하고 있는 금융자산은 고객님의 전체 자산 중 어느정도 비중을 차지합니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn4"
                    :items="qstn4" 
                    v-model="lv_qstn4_sel.value" 
                    class="column"
                    :class="lv_qstn4_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn4')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>5.</i> 고객님의 수입원을 가장 잘 나타내는 것은 어느 것입니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn5"
                    :items="qstn5" 
                    v-model="lv_qstn5_sel.value" 
                    class="column"
                    :class="lv_qstn5_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn5')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p ref="lv_qstn6" class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>6.</i> 고객님의 연간 소득현황은 어떻게 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn6"
                    :items="qstn6" 
                    v-model="lv_qstn6_sel.value" 
                    class="column"
                    :class="lv_qstn6_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn6')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>7.</i> 고객님의 금융상품의 투자 경험기간은 얼마나 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn7"
                    :items="qstn7" 
                    v-model="lv_qstn7_sel.value" 
                    class="column"
                    :class="lv_qstn7_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn7')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>8.</i> 고객님의 금융투자상품 거래 빈도는 어떻게 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn8"
                    :items="qstn8" 
                    v-model="lv_qstn8_sel.value" 
                    class="column"
                    :class="lv_qstn8_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn8')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>9.</i> 고객님의 금융투자상품 취득 및 처분 금액(연간)은 어느정도 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn9"
                    :items="qstn9" 
                    v-model="lv_qstn9_sel.value" 
                    class="column"
                    :class="lv_qstn9_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn9')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>10.</i> 고객님의 해당 금융투자상품을 취득 및 처분하시는 주된 목적은 무엇입니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn10"
                    :items="qstn10" 
                    v-model="lv_qstn10_sel.value" 
                    class="column"
                    :class="lv_qstn10_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn10')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>11.</i> 고객님께서 투자하고자 하는 자금의 투자가능 기간은 얼마나 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn11"
                    :items="qstn11" 
                    v-model="lv_qstn11_sel.value" 
                    class="column"
                    :class="lv_qstn11_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn11')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>12.</i> 고객님의 금융상품 투자목적은 어떻게 되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn12"
                    :items="qstn12" 
                    v-model="lv_qstn12_sel.value" 
                    class="column"
                    :class="lv_qstn12_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn12')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>13.</i>  고객님의 투자원금에 손실이 발생할 경우 다음 중 고객님이 감수할 수 있는 손실 수준은 어느 것입니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn13"
                    :items="qstn13" 
                    v-model="lv_qstn13_sel.value" 
                    class="column"
                    :class="lv_qstn13_sel.error ? 'error' : ''"
                    :disabled="lv_isList || lv_qstn13_sel.disabled"
                    @input="fn_clear('qstn13')"
                  />
                </td>
              </tr>
              <tr>
                <th> <p class="list-num" :class="lv_isList ? 'disabled' : ''"> <i>14.</i>  금융취약소비자에 해당되십니까? </p> </th>
                <td>
                  <mo-radio-wrapper
                    ref="lv_qstn14"
                    :items="qstn14" 
                    v-model="lv_qstn14_sel.value" 
                    class="column"
                    :class="lv_qstn14_sel.error ? 'error' : ''"
                    :disabled="lv_isList"
                    @input="fn_clear('qstn14')"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-button button-page-bottom row">
          <!-- <mo-button point size="large" 
            :disabled="lv_btn_analysis"
          > 성향분석 </mo-button> -->
          <div class="left">
            <mo-button v-if="lv_isProcess"
              point size="large"
              @click="fn_GoNextPage('MSPFS202M')"
              :disabled="lv_btn_next.disabled"
            > 다음 </mo-button>
          </div>
          <mo-button primary size="large" 
            @click="fn_QstnSubmit()"
            :disabled="lv_insert_ok == 'Y' ? false : true"
          > 등록 </mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>    

    <!-- popup308 -->
    <msp-fs-308p
      ref="popup308"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup308Obj"
    ></msp-fs-308p>


  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
  import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'  

  import FSHeader from '~/src/ui/fs/comm/FSHeader'          // header 영역 (공통)
  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)

  import MSPFS308P from '~/src/ui/fs/MSPFS308P'  // 고객정보 팝업


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS110M",
    screenId: "MSPFS110M",
    components: {
      'fs-header': FSHeader,
      'fs-alert-popup': FSAlertPopup,
      'msp-fs-308p': MSPFS308P,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_SetCommCode()
    },
    mounted() {
      this.fn_Init()
      this.$bizUtil.insSrnLog('MSPFS110M')

      if( !FSCommUtil.gfn_isNull(this.$route.params.cob_rname_gb) ) {

        // 개인고객등록에서 등록 후 진입한 경우
        setTimeout(() => {
          this.lv_cob_rname_gb.value = this.$route.params.cob_rname_gb
          this.lv_ed_rname_no.value1 = this.$route.params.ed_rname_no[0]
          this.lv_ed_rname_no.value2 = this.$route.params.ed_rname_no[1]
          
          this.fn_SearchValid({key: 'v'}, 0)
        }, 100)

      } else if( !FSCommUtil.gfn_isNull(this.$route.params.bfcer_cust_no) ) {

        // 고객투자성향(설문등록정보) 체크 후 진입한 경우
        setTimeout(() => {
          this.lv_ed_cust_no.value = this.$route.params.bfcer_cust_no

          this.fn_SearchValid({key: 'v'}, 1)
        }, 100)

      }
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {
          title: '설문등록',
          step: '4',
        },
        pAlertPopupObj: {},
        lv_commCode: {},
        lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
        lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        isMtrans  : this.getStore('fsStore').getters.getState.isMtrans, // 보안키패드 적용 여부
        lv_isClear: false,            // 보안키패드 초기화

        

        // 팝업 객체
        pPopup308Obj: {},

        // 검색조건
        lv_cob_rname_gb: {},          // 실명번호 종류
        lv_ed_rname_no: {},           // 실명번호
        lv_ed_rname_nm: {},           // 성명

        lv_masked_val: '',          // 마스크 변수 체크

        lv_ed_cust_no: {},            // 고객번호
        lv_ed_cust_nm: {},            // 고객명

        lv_rad_reqstn_rsn: {},        // 재진단사유
        rpt_rad_reqstn_rsn : "",
        lv_tlgm_ivt_peson_yn: {},     // 전문투자자여부

        lv_pro_invest_fromTo: {},     // 전문투자자 효력기간

        lv_ivt_cnvs_hop_yn: {},       // 투자권유희망여부
        lv_qstn_expd_yys: {},         // 유효기간 설정여부
        lv_expd_ymd_text: '',         // 유효기간 text

        lv_custSearchType: '',        // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)

        // 조회결과
        lv_rlnmData: {},              // 고객정보 실명정보 매핑 데이터
        lv_tstRctstData: '',          // 고객투자성향결과내역조회(신탁) 매핑 데이터
        lv_selectData: '',            // 고객투자성향결과내역조회(수익증권) 매핑 데이터
        lv_selectFlag: '',            // 조회 데이터 매핑시 watch 방지 flag
        lv_lv_route_cust_no: '',      // route 고객번호

        // 선택항목
        lv_isList: false,

        lv_qstn1_sel: {},
        lv_qstn2_sel: {},
        lv_qstn3_sel: {},
        lv_qstn4_sel: {},
        lv_qstn5_sel: {},
        lv_qstn6_sel: {},
        lv_qstn7_sel: {},
        lv_qstn8_sel: {},
        lv_qstn9_sel: {},
        lv_qstn10_sel: {},
        lv_qstn11_sel: {},
        lv_qstn12_sel: {},
        lv_qstn13_sel: {},
        lv_qstn14_sel: {},

        // submit data
        lv_submit_data: {
          rlnm_no_knd_cd: '',
          rlnm_cnf_no_encr: '',
          ivt_prpns_reg_sno: '',
          bfcer_cust_no: '',
          fnd_cust_nm: '',
          inqry_resp_rslt_val_01: '',
          inqry_resp_rslt_val_02: '',
          inqry_resp_rslt_val_03: '',
          inqry_resp_rslt_val_04: '',
          inqry_resp_rslt_val_05: '',
          inqry_resp_rslt_val_06: '',
          inqry_resp_rslt_val_07: '',
          inqry_resp_rslt_val_08: '',
          inqry_resp_rslt_val_09: '',
          inqry_resp_rslt_val_10: '',
          inqry_resp_rslt_val_11: '',
          inqry_resp_rslt_val_12: '',
          inqry_resp_rslt_val_13: '',
          inqry_resp_rslt_val_14: '',
          inqry_rslt_score: '',
          ivt_prpns_anly_grd_cd: '',
          drvpr_ivt_expr_mms: '',
          ivt_cnvs_hop_yn: '',
          qstn_expd_yys: '',
          expd_stnd_ymd: '',
          expd_exp_ymd: '',
          qstn_reg_systm_knd_sc_cd: '',
          tlgm_ivt_peson_yn: '',
          tlgm_ivt_peson_str_ymd: '',
          tlgm_ivt_peson_end_ymd: '',
          ivt_prpns_lst_chg_dtm: '',
          report_rlnm_cnf_no_encr: '',
          tday_dgns_cnt: '',
          sup_id: '',
          aprv_sno: '',
          thday_reqstn_rsn_cd: '',
        },

        // button
        lv_proc_gb: '',             // 조회/등록여부
        lv_insert_ok: '',           // 등록
        lv_btn_next: {},            // 다음


        /***********************************************************************************
         * F10570167 : 고객정보조회LIST
         * F10570163 : 투자성향설문데이터조회(신탁)
         * F10570165 : 고객투자성향결과내역조회(수익증권)
         * F10570166 : 설문등록(신탁)
         * F10570164 : 설문등록(수익증권)
         ***********************************************************************************/
        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),

      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

      isProcessCheck() {
        return this.getStore('fsStore').getters.getState.isProcess
      },

      // 재진단사유
      radReqstn() {
        let rtn= []
        rtn.push({value: '1', text: '사실관계 착오'})
        rtn.push({value: '2', text: '단순오기'})
        return rtn
      },

      // 전문투자자여부
      tlgmIvtPesonYn() {
        let rtn= []
        rtn.push({value: 'N', text: '일반금융소비자'})
        rtn.push({value: 'Y', text: '전문금융소비자'})
        return rtn
      },      
      
      // 투자권유희망여부
      ivtCnvsHopYn() {
        let rtn= []
        rtn.push({value: 'Y', text: '고객정보제공'})
        rtn.push({value: 'N', text: '고객정보미제공/투자권유불원'})
        return rtn
      },

      // 유효기간 설정여부
      qstnExpdYys() {
        let rtn= []
        rtn.push({value: '2', text: '2년'})
        rtn.push({value: '1', text: '1년'})
        return rtn
      },


      // 투자자정보 확인서 항목
      qstn1() {
        let rtn= []
        rtn.push({value: '1', text: '19세 이하'})
        rtn.push({value: '2', text: '20세 ~ 40세'})
        rtn.push({value: '3', text: '41세 ~ 64세'})
        rtn.push({value: '4', text: '65세 ~ 79세'})
        rtn.push({value: '5', text: '80세 이상'})
        rtn.push({value: '6', text: '해당없음'})
        return rtn
      },
      qstn2() {
        let rtn= []
        rtn.push({value: '1', text: '은행 예, 적금, 국채, 지방채, MMF, CMA 등'})
        rtn.push({value: '2', text: '금융채, 신용도가 높은 회사채, 채권형 펀드, 원금보장형 ELF 등'})
        rtn.push({value: '3', text: '신용도 중간 등급의 회사채, 원금의 일부만 보장되는 ELF, 혼합형펀드 등'})
        rtn.push({value: '4', text: '신용도가 낮은 회사채, 주식, 원금이 보장되지 않는 ELF, 시장수익률 수준의 수익을 추구하는 주식형펀드 등'})
        rtn.push({value: '5', text: 'ELW, 선물옵션, 시장수익률 이상의 수익을 추구하는 주식형 펀드, 파생상품을 투자하는 펀드, 신용거래 등'})
        return rtn
      },
      qstn3() {
        let rtn= []
        rtn.push({value: '1', text: '주식, 펀드, 채권 등 금융투자상품에 대해 전혀 모른다.'})
        rtn.push({value: '2', text: '주식, 펀드, 채권 등 금융투자상품을 구별할 수 있다.'})
        rtn.push({value: '3', text: '주식, 펀드, 채권 등 금융투자상품에 대해 알고 있으며, 설명을 들으면 투자결정을 내릴 수 있다.'})
        rtn.push({value: '4', text: '파생상품을 포함하여 금융투자상품을 잘 알고 있으며, 스스로 투자 결정을 내릴 수 있다.'})
        return rtn
      },
      qstn4() {
        let rtn= []
        rtn.push({value: '1', text: '5% 이내'})
        rtn.push({value: '2', text: '5% 이상 ~ 10% 이내'})
        rtn.push({value: '3', text: '10% 이상 ~ 20% 이내'})
        rtn.push({value: '4', text: '20% 이상'})
        return rtn
      },
      qstn5() {
        let rtn= []
        rtn.push({value: '1', text: '현재 일정한 수입이 발생하고 있으며, 향후 현재 수준을 유지하거나 증가할 것으로 예상'})
        rtn.push({value: '2', text: '현재 일정한 수입이 발생하고 있으나, 향후 감소하거나 불안정할 것으로 예상'})
        rtn.push({value: '3', text: '현재 일정한 수입이 없으며, 연금이 주 수입원임'})
        return rtn
      },
      qstn6() {
        let rtn= []
        rtn.push({value: '1', text: '1천만원 이하'})
        rtn.push({value: '2', text: '3천만원 이하'})
        rtn.push({value: '3', text: '5천만원 이하'})
        rtn.push({value: '4', text: '1억원 이하'})
        rtn.push({value: '5', text: '1억원 초과'})
        return rtn
      },
      qstn7() {
        let rtn= []
        rtn.push({value: '1', text: '전혀 없음'})
        rtn.push({value: '2', text: '1년 미만'})
        rtn.push({value: '3', text: '3년 미만'})
        rtn.push({value: '4', text: '5년 미만'})
        rtn.push({value: '5', text: '5년 이상'})
        return rtn
      },
      qstn8() {
        let rtn= []
        rtn.push({value: '1', text: '매일'})
        rtn.push({value: '2', text: '주간'})
        rtn.push({value: '3', text: '월간'})
        rtn.push({value: '4', text: '분기 1회'})
        rtn.push({value: '5', text: '연 1회'})
        return rtn
      },
      qstn9() {
        let rtn= []
        rtn.push({value: '1', text: '1백만원 이하'})
        rtn.push({value: '2', text: '1천만원 이하'})
        rtn.push({value: '3', text: '1억원 이하'})
        rtn.push({value: '4', text: '1억원 초과'})
        return rtn
      },
      qstn10() {
        let rtn= []
        rtn.push({value: '1', text: '학비'})
        rtn.push({value: '2', text: '생활비'})
        rtn.push({value: '3', text: '주택마련'})
        rtn.push({value: '4', text: '자산증식'})
        rtn.push({value: '5', text: '채무상환'})
        return rtn
      },
      qstn11() {
        let rtn= []
        rtn.push({value: '1', text: '6개월 이내'})
        rtn.push({value: '2', text: '6개월 이상 ~ 1년 이내'})
        rtn.push({value: '3', text: '1년 이상 ~ 2년 이내'})
        rtn.push({value: '4', text: '2년 이상 ~ 3년 이내'})
        rtn.push({value: '5', text: '3년 이상'})
        return rtn
      },
      qstn12() {
        let rtn= []
        rtn.push({value: '1', text: '적극적 매매를 통한 수익을 원하며 초과하는 손실위험도 감내가능'})
        rtn.push({value: '2', text: '적극적 매매를 통한 수익 실현 목적'})
        rtn.push({value: '3', text: '시장가격(예: 주가지수) 변동 추이와 비슷한 수준의 수익 실현'})
        rtn.push({value: '4', text: '채권이자, 주식배당 정도의 수익 실현 목적'})
        rtn.push({value: '5', text: '기존 보유자산에 대한 위험 해지 목적'})
        return rtn
      },
      qstn13() {
        let rtn= []
        rtn.push({value: '1', text: '무슨 일이 있어도 투자 원금이 보전되어야 한다.'})
        rtn.push({value: '2', text: '이자율 수준의 수익 기대 및 손실을 감내할 수 있다.'})
        rtn.push({value: '3', text: '시장(주가지수)에 비례한 수익 기대 및 손실을 감내할 수 있다.'})
        rtn.push({value: '4', text: '시장수익률을 초과하는 수익 기대 및 손실을 감내할 수 있다.'})
        return rtn
      },
      qstn14() {
        let rtn= []
        rtn.push({value: '1', text: '예'})
        rtn.push({value: '2', text: '아니오'})
        return rtn
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {

      // 프로세스 체크
      'isProcessCheck': {
        handler(after, before) {
          console.log('isProcessCheck > ', after)
          this.fn_Init()
        },
        deep: true
      },


      // 전문투자자여부
      'lv_tlgm_ivt_peson_yn.value': {
        handler(after, before) {
          if( this.lv_selectFlag != 'Y' ) {
            this.fn_tlgm_ivt_peson_yn(after)
          }
        },
        deep: true
      },


      // 투자권유 희망여부
      'lv_ivt_cnvs_hop_yn.value': {
        handler(after, before) {
          if( this.lv_selectFlag != 'Y' ) {
            this.fn_ivt_cnvs_hop_yn(after)            
          }
        },
        deep: true
      },

      // 유효기간 설정여부
      'lv_qstn_expd_yys.value': {
        handler(after, before) {
          if( this.lv_selectFlag != 'Y' ) {
            let val               = FSCommUtil.gfn_isNull(after) ? 2 : Number(after)
            this.lv_expd_ymd_text = FSCommUtil.gfn_dateReplace(2, FSCommUtil.gfn_strToday(1)) + ' ~ ' + FSCommUtil.gfn_dateReplace(2, this.fn_PlusYear(0, val))            
          }
        },
        deep: true
      },

      // 14번 항목
      'lv_qstn14_sel.value': {
        handler(after, before) {
          if( this.lv_selectFlag != 'Y' && after == '2' ) {
            let t_popupObj = {
              closeFunc: this.fn_Qstn14_sel_yes,
              confirm: true,
              confirmFunc: this.fn_Qstn14_sel_no,
              content: "금융취약소비자 대상에 해당되지 않습니까?"
            }
            this.fn_AlertPopup(1, t_popupObj)
          }
        },
        deep: true
      },

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
        * Function명 : fn_SetMaskedTxt
        * 설명       : 보안키패드 체크
        ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_val = val                
        if(this.lv_masked_val.length >= 7) {
          this.$nextTick(()=>{            
            this.fn_SearchValid({key: 'v'}) 
          })          
        }
      },

      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = [
          {'cId':'211', 'dayChkYn':'N'},          
        ]
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },

      fn_SetCommCodeResult(pResult) {
        this.lv_commCode = pResult

        let t_codeList = []
        pResult.code211.forEach((item) => {
          if( item.value == '01' || item.value == '04' || item.value == '05' ) {
            t_codeList.push(item)
          }
        })

        this.lv_commCode.code211 = t_codeList
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS110M] fn_Init...')
        console.log('isProcess :: ' + this.lv_isProcess)

        //보안키패드 관련
        this.lv_isClear = !this.lv_isClear ? true : false
        this.lv_masked_val = '';

        // button
        this.lv_proc_gb             = ''                                                            // 조회/등록여부
        this.lv_insert_ok           = ''                                                            // 등록
        this.lv_btn_next            = {disabled: true}                                              // 다음버튼


        // 조회결과
        this.lv_rlnmData      = {}                                                                  // 고객정보 실명정보 매핑 데이터
        this.lv_tstRctstData  = ''                                                                  // 고객투자성향결과내역조회(신탁) 매핑 데이터        
        this.lv_selectData    = ''                                                                  // 고객투자성향결과내역조회(수익증권) 매핑 데이터
        this.lv_selectFlag    = ''                                                                  // 조회 데이터 매핑시 watch 방지 flag
        this.lv_route_cust_no = ''                                                                  // route 고객번호


        // 검색조건
        this.lv_cob_rname_gb        = FSCommUtil.gfn_dataSet(0, '01')                               // 실명번호 종류
        this.lv_ed_rname_no = {                                                                     // 실명번호
          error: false,
          disabled: false,
          value1: '',
          value2: '',
        }
        this.lv_ed_rname_nm         = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_rname_nm')        // 성명

        this.lv_ed_cust_no          = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_cust_no')        // 고객번호
        this.lv_ed_cust_nm          = FSCommUtil.gfn_dataSet(1)                                     // 고객명
        this.lv_rad_reqstn_rsn      = FSCommUtil.gfn_dataSet(1, '', true)                           // 재진단사유
        
        this.lv_tlgm_ivt_peson_yn   = FSCommUtil.gfn_dataSet(0, 'N', false)                         // 전문투자자여부

        this.lv_custSearchType      = ''                                                            // 고객정보조회 type

        // 전문투자자 효력기간
        this.lv_pro_invest_fromTo   = FSCommUtil.gfn_dataSet(0, [FSCommUtil.gfn_dateReplace(1, FSCommUtil.gfn_strToday(1)), FSCommUtil.gfn_dateReplace(1, this.fn_PlusYear(0, 2))], true, '', 'pro_invest_fromTo')  

        this.lv_ivt_cnvs_hop_yn     = FSCommUtil.gfn_dataSet(1, '', false)                          // 투자권유희망여부
        this.lv_qstn_expd_yys       = FSCommUtil.gfn_dataSet(1, '', false)                          // 유효기간 설정여부

        this.lv_expd_ymd_text       = ''

        // 선택항목 활성/비활성
        this.lv_isList              = false


        this.lv_qstn1_sel           = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn2_sel           = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn3_sel           = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn4_sel           = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn5_sel           = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn6_sel           = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn7_sel           = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn8_sel           = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn9_sel           = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn10_sel          = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn11_sel          = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn12_sel          = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn13_sel          = FSCommUtil.gfn_dataSet(1, '', false)
        this.lv_qstn14_sel          = FSCommUtil.gfn_dataSet(1, '', false)

        /* this.lv_qstn1_sel           = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn1')
        this.lv_qstn2_sel           = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn2')
        this.lv_qstn3_sel           = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn3')
        this.lv_qstn4_sel           = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn4')
        this.lv_qstn5_sel           = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn5')
        this.lv_qstn6_sel           = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn6')
        this.lv_qstn7_sel           = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn7')
        this.lv_qstn8_sel           = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn8')
        this.lv_qstn9_sel           = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn9')
        this.lv_qstn10_sel          = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn10')
        this.lv_qstn11_sel          = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn11')
        this.lv_qstn12_sel          = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn12')
        this.lv_qstn13_sel          = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn13')
        this.lv_qstn14_sel          = FSCommUtil.gfn_dataSet(1, '', false, '', 'lv_qstn14') */

        this.fn_quest_value_set(1)

        // 설문등록 데이터 초기화
        this.fn_ClearSubmitData()

        if(this.lv_isProcess) {
          if(this.lv_basInfo.procTyp == 'grpNew') {
            this.lv_ed_rname_no.error = true
          }
        }
      },


      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },


      /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 고객정보
       ******************************************************************************/
      fn_OpenPopup(type) {
        switch (type) {
          case 0:
            this.pPopup308Obj.screenId = 'MSPFS110M'
            this.$refs.popup308.fn_Open()
            break
        }
      },


      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0: 고객정보
       ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        switch (type) {
          case 0:
            console.log('고객정보 callback...')

            // 고객번호 매핑 후 고객정보조회
            this.lv_ed_cust_no.value = pData
            this.fn_SearchValid({key: 'v'}, 1)
            break
        }
      },


      /******************************************************************************
       * Function명 : fn_MinusYear
       * 설명       : 1년/2년 후 날짜구하기
       ******************************************************************************/
      fn_PlusYear(pType, pValue) {
        let objDate = new Date()
        objDate.setFullYear(objDate.getFullYear() + pValue)
        objDate.setDate(objDate.getDate() - 1)

        let month = objDate.getMonth() + 1
        return objDate.getFullYear().toString() + (month.toString().length == 1 ? "0" : "") + month.toString() + (objDate.getDate().toString().length == 1 ? "0" : "") + objDate.getDate().toString()
      },


      /******************************************************************************
       * Function명 : fn_BtnController
       * 설명       : 버튼 활성화/비활성화
       ******************************************************************************/
      fn_BtnController() {
        console.log('fn_BtnController.....')

        if( this.lv_proc_gb == 'I' ) {
          this.lv_insert_ok = 'Y'

          // 실명번호 검색일 경우에만 성명 활성화
          if( this.lv_custSearchType == '0' ) {
            this.lv_ed_rname_nm.disabled = false
          }

        } else {
          this.lv_insert_ok = ''

        }

      },


      /******************************************************************************
       * Function명 : fn_CustInfoListSearch, fn_CustInfoListSearchResult
       * 설명       : 고객정보조회LIST
       ******************************************************************************/
      fn_CustInfoListSearch() {
        console.log('fn_CustInfoListSearch.....')

        // 초기화
        this.lv_proc_gb = ''
        this.lv_insert_ok = ''

        let t_data = {bfcer_cust_no : this.lv_ed_cust_no.value}
        if( this.lv_custSearchType == 0 ) {
          t_data.rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2
        }

        FSInfoUtil.commInfo(this, 1, t_data, this.fn_CustInfoListSearchResult)
      },

      fn_CustInfoListSearchResult(pResultData) {
        console.log('fn_CustInfoListSearchResult.....')

        let t_data = pResultData.data.sy100500_O_00VO
        console.log(t_data)

        if( t_data.length > 0 ) {
          if( this.lv_custSearchType == 0) {
            // 고객명
            this.lv_ed_rname_nm.value = t_data[0].fnd_cust_nm

            // 실명번호정보 매핑
            this.lv_rlnmData.rlnm_cnf_no_encr = t_data[0].rlnm_cnf_no_encr //기존코드 사용 시, 고객번호로 조회하면 line1648 에서 실명번호가 빈값으로 되어 빈값 매핑됨
            // this.lv_rlnmData.rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2

            this.lv_rlnmData.rlnm_no_knd_cd   = this.lv_cob_rname_gb.value

            // 고객번호 매핑
            this.lv_rlnmData.bfcer_cust_no    = t_data[0].bfcer_cust_no

            // route 고객번호 매핑
            this.lv_route_cust_no             = t_data[0].bfcer_cust_no

          } else {
            // 고객명
            this.lv_ed_cust_nm.value = t_data[0].fnd_cust_nm

            // 실명번호정보 매핑
            this.lv_rlnmData.rlnm_cnf_no_encr = t_data[0].rlnm_cnf_no_encr
            this.lv_rlnmData.rlnm_no_knd_cd   = t_data[0].bfcer_rlnm_no_knd_cd

            // 고객번호 매핑
            this.lv_rlnmData.bfcer_cust_no    = t_data[0].bfcer_cust_no

            // route 고객번호 매핑
            this.lv_route_cust_no             = t_data[0].bfcer_cust_no

          }
        }

        this.fn_TstRctstSearch()
      },


      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회
       ******************************************************************************/
      fn_SearchValid(event, type) {
        console.log('fn_SearchValid.....')

        // 조회버튼 클릭시
        if( FSCommUtil.gfn_isNull(type) ) {
          if( !FSCommUtil.gfn_isNull(this.lv_ed_rname_no.value1) && !FSCommUtil.gfn_isNull(this.lv_ed_rname_no.value2) ) {
            this.lv_cob_rname_gb.error = false
            this.lv_ed_rname_no.error = false

            if( FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) != 6 || !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value1) ) {
              FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
              this.lv_ed_rname_no.error = true
              this.$refs['ed_rname_no1'].focus()
              return

            }
            
            if(!this.isMobile) { //모바일아닌 경우
              if( FSCommUtil.gfn_length(this.lv_ed_rname_no.value2) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value2) ) {
                FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
                this.lv_ed_rname_no.error = true
                this.$refs['ed_rname_no2'].focus()
              return
              }
            } else {  // 모바일인 경우
              if(this.lv_masked_val.length !== 7) {
                FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
                this.lv_ed_rname_no.error = true
                return
              }
            }
            
            // 실명번호종류/실명번호 비활성화
            this.lv_ed_cust_no.disabled = true

            // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)
            this.lv_custSearchType = 0

            // 고객정보조회
            this.fn_CustInfoListSearch()

          } else if( !FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value) ) {

            if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value ) ) {
              FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
              this.lv_ed_cust_no.error = true
              this.$refs['ed_cust_no'].focus()
              return 
            }

            // 실명번호종류/실명번호 비활성화
            this.lv_cob_rname_gb.disabled = true
            this.lv_ed_rname_no.disabled = true

            // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)
            this.lv_custSearchType = 1

            // 고객정보조회
            this.fn_CustInfoListSearch()

          } else {
            let t_popupObj = {
              content: "주민등록번호 또는 고객번호는 필수입력 항목입니다."
            }
            this.fn_AlertPopup(0, t_popupObj)
          }
        }


        // 실명번호/고객번호 입력시
        if( type == 0 ) {

          // 실명번호
          if( FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) == 6 ) {
            if( FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile ) {
              this.$refs['ed_rname_no2'].focus()
            }
          }

          if( FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) == 6  && ((!this.isMobile && FSCommUtil.gfn_length(this.lv_ed_rname_no.value2) == 7) || this.isMobile && this.lv_masked_val.length == 7)) {
            this.lv_cob_rname_gb.error = false
            this.lv_ed_rname_no.error = false

            if( !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value1) ) {
              FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
              this.lv_ed_rname_no.error = true
              this.$refs['ed_rname_no1'].focus()
              return

            }

            if(!this.isMobile) { //모바일 아닌경우
              if( FSCommUtil.gfn_length(this.lv_ed_rname_no.value2) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value2) ) {
                FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
                this.lv_ed_rname_no.error = true
                this.$refs['ed_rname_no2'].focus()
              return
              }
            } else { //모바일인 경우
              if( this.lv_masked_val.length !== 7 ) {
                FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
                this.lv_ed_rname_no.error = true
                return
              }
            }

            if( FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile ) {
              // 실명번호종류/실명번호 비활성화
              this.lv_ed_cust_no.disabled = true

              // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)
              this.lv_custSearchType = type

              // 고객정보조회
              this.fn_CustInfoListSearch()
            }
          }

        } else if( type == 1 ) {

          // 고객번호
          if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) == 7 ) {
            this.lv_ed_cust_no.error = false
            
            if(!FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value) ) {
              FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
              this.lv_ed_cust_no.error = true
              this.$refs['ed_cust_no'].focus()
              return 

            } else {
              if( FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile ) {
                // 실명번호종류/실명번호 비활성화
                this.lv_cob_rname_gb.disabled = true
                this.lv_ed_rname_no.disabled = true

                // 고객정보조회 type 세팅(0: 실명번호 / 1: 고객번호)
                this.lv_custSearchType = type

                // 고객정보조회
                this.fn_CustInfoListSearch()
              }
            }
          }

        }
      },


      /******************************************************************************
       * Function명 : fn_TstRctstSearch, fn_TstRctstSearchResult, fn_TstRctstSub1, fn_TstRctstSub2, fn_TstRctstSub3, fn_TstRctstSearchFail
       * 설명       : 투자성향설문데이터조회(신탁)
       ******************************************************************************/
      async fn_TstRctstSearch() {
        console.log('fn_TstRctstSearch.....')

        this.lv_proc_gb = 'S'

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570163_S'
        this.eaiCommObj.params = {
          bfcer_cust_no     : this.lv_rlnmData.bfcer_cust_no,                                      // 수익증권고객번호
          rlnm_cnf_no_encr  : FSCommUtil.gfn_isNull(this.lv_rlnmData.rlnm_cnf_no_encr) ? '' : this.lv_rlnmData.rlnm_cnf_no_encr,        // 실명확인번호
          rlnm_no_knd_cd    : this.lv_cob_rname_gb.value,                                       // 실명확인번호구분코드
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_TstRctstSearchResult, this.fn_TstRctstSearchFail)
      },

      fn_TstRctstSearchResult(pResultData) {
        console.log('fn_TstRctstSearchResult.....')

        let t_popupObj = {}
        let t_data = pResultData.data
        console.log(t_data)

        if ( t_data.qstn_reg_systm_knd_sc_cd == 2 && // 설문등록 시스템종류 구분코드(수익: 1, 신탁: 2)
          !FSCommUtil.gfn_isNull(t_data.ivt_prpns_lst_chg_dtm) && !FSCommUtil.gfn_isNull(t_data.ivt_prpns_lst_chg_dtm) ) {

          // 고객투자성향결과내역조회(신탁) 결과 매핑
          this.lv_tstRctstData = t_data 

          t_popupObj = {
            closeFunc: this.fn_TstRctstSub2,
            confirm: true,
            confirmFunc: this.fn_TstRctstSub1,
            content: "해당 고객이 [일반투자자]인 경우 '확인'을, \n\n[전문투자자]인 경우에는 '취소'를 누르세요."
          }
          this.fn_AlertPopup(0, t_popupObj)
        } else {

          // 고객투자성향결과내역조회(수익증권)
          this.fn_SelectDataSearch()
        }
      },

      fn_TstRctstSub1() {
        console.log('fn_TstRctstSub1.....')

        let t_popupObj = {
          closeFunc: this.fn_TstRctstSub3,
          confirm: true,
          confirmFunc: this.fn_TstRctstQstnSubmit,
          content: '신탁상품 가입시 등록한 설문이 있습니다. 동일한 설문으로 등록하시겠습니까?'
        }
        this.fn_AlertPopup(0, t_popupObj)
      },

      fn_TstRctstSub2() {
        console.log('fn_TstRctstSub2.....')

        let t_popupObj = {
          cancel: false,              
          confirm: true,
          confirmFunc: this.fn_SelectDataSearch,
          content: "[전문투자자]의 경우 상담설문을 신규로 등록하시기 바랍니다."
        }
        this.fn_AlertPopup(0, t_popupObj)
      },

      fn_TstRctstSub3() {
        console.log('fn_TstRctstSub3.....')

        let t_popupObj = {
          cancel: false,
          confirm: true,
          confirmFunc: this.fn_SelectDataSearch,
          content: "취소되었습니다. 기존 등록된 내용으로 조회합니다."
        }
        this.fn_AlertPopup(0, t_popupObj)
      },      

      fn_TstRctstSearchFail() {
        console.log('fn_TstRctstSearchFail.....')

        // t_popupObj.content = '화면처리 시 오류가 발생했습니다.'
        // this.fn_AlertPopup(0, t_popupObj)

        let t_popupObj = {
          cancel: false,
          confirm: true,
          confirmFunc: this.fn_SelectDataSearch,
          content: '타시스템(신탁수탁)과 통신 중 이상이 발생하였습니다.\n기존 수익증권시스템 등록 자료로 조회합니다.',
        }
        this.fn_AlertPopup(0, t_popupObj)
      },


      /******************************************************************************
       * Function명 : fn_SelectDataSearch, fn_SelectDataSearchResult
       * 설명       : 고객투자성향결과내역조회(수익증권)
       ******************************************************************************/
      async fn_SelectDataSearch() {
        console.log('fn_SelectDataSearch.....')

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570165_S'
        this.eaiCommObj.params = {
          bfcer_cust_no     : FSCommUtil.gfn_isNull(this.lv_rlnmData.bfcer_cust_no ) ? '' : this.lv_rlnmData.bfcer_cust_no ,                    // 수익증권고객번호
          rlnm_cnf_no_encr  : FSCommUtil.gfn_isNull(this.lv_rlnmData.rlnm_cnf_no_encr) ? '' : this.lv_rlnmData.rlnm_cnf_no_encr,                // 실명확인번호
          rlnm_no_knd_cd    : FSCommUtil.gfn_isNull(this.lv_rlnmData.rlnm_no_knd_cd) ? '' : this.lv_rlnmData.rlnm_no_knd_cd,                    // 실명확인번호구분코드
        }



        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SelectDataSearchResult)
      },

      fn_SelectDataSearchResult(pResultData) {
        console.log('fn_SelectDataSearchResult.....')

        let lv_vm = this
        let t_popupObj = {}
        let t_data = pResultData.data

        this.lv_selectData = t_data   // 고객투자성향결과내역조회(수익증권) 결과 매핑
        this.lv_selectFlag = 'Y'      // 조회시 watch 방지용 flag

        // 중복 호출 방지
        if( this.lv_proc_gb == 'I' ) return

        if( t_data.error_msg == '809900' ) {
          // 고객투자성향결과내역조회(수익증권) 결과 존재할 경우 값 세팅

          // 투자성향 alert 메세지 처리
			    let aInvs_dipos_nm = t_data.invs_dipos_nm
			    if( aInvs_dipos_nm == '' ) {
            if( this.tlgm_ivt_peson_yn == 'Y' ) {
                aInvs_dipos_nm = '결과없음(전문투자자)'
            } else {
                aInvs_dipos_nm = '결과없음'
            }
			    }

          let infoMsg = ''
			    if( this.lv_insert_ok == 'Y' ) {

            if( this.lv_isProcess == true ) {
              infoMsg = t_data.fnd_cust_nm + ' 고객님의 새로운 투자성향은 ' + aInvs_dipos_nm + ' 입니다. 성향분석 결과로 이동하시겠습니까?'
              t_popupObj = {
                cancel: false,
                confirm: true,
                confirmFunc: this.fn_ReportPrint,
                content: infoMsg,
                confirmData: {
                  bfcer_cust_no: this.lv_rlnmData.bfcer_cust_no
                }
              }
              this.fn_AlertPopup(1, t_popupObj)

            } else {
              infoMsg = t_data.fnd_cust_nm + ' 고객님의 새로운 투자성향은 ' + aInvs_dipos_nm + ' 입니다. 성향분석을 완료하시겠습니까?'
              t_popupObj = {
                cancel: false,
                content: [infoMsg],                
                confirm: true,
                confirmFunc: lv_vm.fn_ReportPrint,
                confirmData: {
                  bfcer_cust_no: this.lv_rlnmData.bfcer_cust_no
                },
              }
              this.fn_AlertPopup(9, t_popupObj)
            }

			    } else {
            infoMsg = t_data.fnd_cust_nm + ' 고객님의 기존 투자성향은 ' + aInvs_dipos_nm + ' 입니다.'
            t_popupObj = {
              content: infoMsg,
            }
            this.fn_AlertPopup(0, t_popupObj)
			    }


          // 전문투자자효력기간
          if( t_data.tlgm_ivt_peson_yn == 'Y' ) { // 전문투자자인경우
            this.lv_ivt_cnvs_hop_yn.disabled  = true  // 투자권유희망여부 비활성화
            this.lv_pro_invest_fromTo.value   = [t_data.tlgm_ivt_peson_str_ymd, t_data.tlgm_ivt_peson_end_ymd]
          }

          // 유효기간 text
          this.lv_expd_ymd_text = FSCommUtil.gfn_dateReplace(2, t_data.expd_stnd_ymd) + ' ~ ' + FSCommUtil.gfn_dateReplace(2, t_data.expd_exp_ymd)


          // 투자권유희망여부
          this.lv_ivt_cnvs_hop_yn.value = t_data.ivt_cnvs_hop_yn
          // this.lv_ivt_cnvs_hop_yn.value = 'N'

          if( this.lv_ivt_cnvs_hop_yn.value == 'N' ) {
            // 유효기간 설정여부 세팅
            this.fn_ivt_cnvs_hop_yn('N')

          } else {
            
            // 유효기간 설정여부 세팅
					  let qstn_expd_yys = t_data.qstn_expd_yys
            if( qstn_expd_yys == '3' || FSCommUtil.gfn_isNull(qstn_expd_yys) ) {
              this.lv_qstn_expd_yys.value = '2'
            } else {
              this.lv_qstn_expd_yys.value = qstn_expd_yys
            }
          }

					// 투자권유희망여부가 N이면서 넘어온 취약투자자여부 값이 2이면 0으로 변환해서 화면에서 안보이게 한다.
          if( t_data.inqry_resp_rslt_val_14 == '9' && t_data.ivt_cnvs_hop_yn == 'N') {
            t_data.inqry_resp_rslt_val_14 = '0'
          }

          // 설문지 값 셋팅
          this.fn_quest_value_set('', t_data)

          // 재진단 횟수(당일 3회 등록 제한은 삭제)
          let tday_dgns_cnt = Number(t_data.tday_dgns_cnt)
          // let tday_dgns_cnt = 1
				  
          // if( tday_dgns_cnt >= 3 ) {
          //   this.lv_proc_gb = 'R'
          // } else {
          //   this.lv_proc_gb = 'I'
          // }
          
          this.lv_proc_gb = 'I'

          if( tday_dgns_cnt >= 1 ) { 
            
            // 재진단사유 초기화 및 활성화
            this.lv_rad_reqstn_rsn.value = ''
            this.lv_rad_reqstn_rsn.disabled = false

            // 3번, 13번 문항 비활성화
            this.lv_qstn3_sel.value = t_data.inqry_resp_rslt_val_03
            this.lv_qstn3_sel.disabled = true

            this.lv_qstn13_sel.value = t_data.inqry_resp_rslt_val_13
            this.lv_qstn13_sel.disabled = true

            // 다음버튼 활성화
            this.lv_btn_next.disabled = false

          } else {

            // 재진단사유 초기화 및 비활성화
            this.lv_rad_reqstn_rsn.value = ''
            this.lv_rad_reqstn_rsn.disabled = true

            // 3번, 13번 문항 활성화
            this.lv_qstn3_sel.disabled = false
            this.lv_qstn13_sel.disabled = false

            // 다음버튼 활성화
            this.lv_btn_next.disabled = true

          }

        } else {
          
          // 고객투자성향결과내역조회(수익증권) 결과 미존재할 경우 초기화
          this.lv_proc_gb = 'I'

          // 설문지 값 셋팅
          this.fn_quest_value_set()

        }

        // 버튼 컨트롤
        this.fn_BtnController()

        // 조회시 watch 방지용 flag clear
        setTimeout(() => {
          this.lv_selectFlag = ''
        }, 100)
      },


      /***************************************************************************************
       * Function명 : fn_quest_value_set
       * 설명       : 설문지 값 셋팅
      *****************************************************************************************/
      fn_quest_value_set(set_mode, pData) {
        console.log('fn_quest_value_set.....')

        // 2021-03-24 김재연 ASR210200796_투자상품 판매 프로세스 강화 개발 요청(2)    
        // - 신규계좌개설시 투자성향 질문지를 받을 때마다 투자자금성향(11/12/13번)을 새로이 업데이트 할 수 있도록 공란으로 관리  
        //   * 1번 문항도 공란으로 두어, 나이 구간 변경에 대한 확인 진행
        // 모두 지우기를 원하는 경우
        if( set_mode == '1' ) {
          this.lv_qstn1_sel.value = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_01
        }
        
        this.lv_qstn2_sel.value   = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_02
        this.lv_qstn3_sel.value   = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_03
        this.lv_qstn4_sel.value   = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_04
        this.lv_qstn5_sel.value   = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_05
        this.lv_qstn6_sel.value   = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_06
        this.lv_qstn7_sel.value   = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_07
        this.lv_qstn8_sel.value   = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_08
        this.lv_qstn9_sel.value   = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_09
        this.lv_qstn10_sel.value  = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_10

        // 모두 지우기를 원하는 경우
        // 2021.03.24 김재연 ASR210200796_투자상품 판매 프로세스 강화 개발 요청(2)	
        if( set_mode == '1' ) {
          this.lv_qstn11_sel.value = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_11
          this.lv_qstn12_sel.value = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_12
          this.lv_qstn13_sel.value = FSCommUtil.gfn_isNull(pData) ? '' : pData.inqry_resp_rslt_val_13
        }

        if( !FSCommUtil.gfn_isNull(pData) ) {
          this.lv_qstn14_sel.value  = pData.inqry_resp_rslt_val_14
        }


        let rlnm_cnf_no_encr = ''         //실명번호
        let rlnm_no_knd_cd   = ''         //실명번호종류

        if( !FSCommUtil.gfn_isNull(this.lv_rlnmData) && !FSCommUtil.gfn_isNull(this.lv_rlnmData.rlnm_no_knd_cd) ) {
          rlnm_cnf_no_encr = this.lv_rlnmData.rlnm_cnf_no_encr
          rlnm_no_knd_cd   = this.lv_rlnmData.rlnm_no_knd_cd

        } else {                                  
          let temp = this.lv_ed_rname_no.value2
          if ( this.isMobile ) {
            temp = this.lv_masked_val                                                //모바일인 경우 mask 값 사용
          }

          rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + temp.substring(0, 1)
          rlnm_no_knd_cd   = this.lv_cob_rname_gb.value

        }

        // 전문투자자 아니고 투자권유 미제공아니고 실명번호값 있을 경우
        if( this.lv_tlgm_ivt_peson_yn.value != 'Y' && this.lv_ivt_cnvs_hop_yn.value != 'N' && !FSCommUtil.gfn_isNull(rlnm_cnf_no_encr) ) {

          // 2021-03-24 김재연 ASR210200796_투자상품 판매 프로세스 강화 개발 요청(2)
          // - (기존고객정보등록의경우)주민번호 나이에 따른 자동 입력
            if(Number(rlnm_no_knd_cd) < 7) {
            let tAge = FSCommUtil.gfn_getAge(rlnm_no_knd_cd, rlnm_cnf_no_encr);

            // 화면초기화 셋팅일때는 검사안함.
            if( set_mode != '1' ) {
              
              // 1번 문항 자동셋팅
              if(tAge < 20) this.lv_qstn1_sel.value = "1"
              else if(tAge >= 20 && tAge <= 40) this.lv_qstn1_sel.value = "2"
              else if(tAge >= 41 && tAge <= 64) this.lv_qstn1_sel.value = "3"
              else if(tAge >= 65 && tAge <= 79) this.lv_qstn1_sel.value = "4"
              else this.lv_qstn1_sel.value = "5"
              
              // 14번 문항 자동셋팅
              if(tAge < 19 || tAge >= 65) this.lv_qstn14_sel.value = "1"
            }
            
          } else {
            this.lv_qstn1_sel.value = '6'

          }
        
          // if( FSCommUtil.gfn_isNull(this.lv_qstn10_sel.value) ) {
          //   this.lv_qstn10_sel.value = '9'
          // }

        } else {
          this.lv_qstn1_sel.value   = ''
          this.lv_qstn2_sel.value   = ''
          this.lv_qstn3_sel.value   = ''
          this.lv_qstn4_sel.value   = ''
          this.lv_qstn5_sel.value   = ''
          this.lv_qstn6_sel.value   = ''
          this.lv_qstn7_sel.value   = ''
          this.lv_qstn8_sel.value   = ''
          this.lv_qstn9_sel.value   = ''
          this.lv_qstn10_sel.value  = ''
          this.lv_qstn11_sel.value  = ''
          this.lv_qstn12_sel.value  = ''
          this.lv_qstn13_sel.value  = ''
          this.lv_qstn14_sel.value  = ''

        }
      },

      /***************************************************************************************
       * Function명 : fn_tlgm_ivt_peson_yn
       * 설명       : 전문투자자여부 변경
      *****************************************************************************************/
      fn_tlgm_ivt_peson_yn(flag) {
        console.log('fn_tlgm_ivt_peson_yn.....')

        // 유효기간
        this.lv_expd_ymd_text = FSCommUtil.gfn_dateReplace(2, FSCommUtil.gfn_strToday(1)) + ' ~ ' + FSCommUtil.gfn_dateReplace(2, this.fn_PlusYear(1, 2))

	      if( flag != "Y" ) { // 일반금융소비자

          // 투자권유희망여부
          this.lv_ivt_cnvs_hop_yn.value = ''

          // 설문지 값 셋팅
          this.fn_quest_value_set()
        }
      },

      /***************************************************************************************
       * Function명 : fn_ivt_cnvs_hop_yn
       * 설명       : 투자권유희망여부 변경
      *****************************************************************************************/
      fn_ivt_cnvs_hop_yn(flag) {
        console.log('fn_ivt_cnvs_hop_yn.....')

        // 유효기간
        this.lv_expd_ymd_text = FSCommUtil.gfn_dateReplace(2, FSCommUtil.gfn_strToday(1)) + ' ~ ' + FSCommUtil.gfn_dateReplace(2, this.fn_PlusYear(1, 2))

        if( flag == 'N') {
          let t_popupObj = {
            content: "투자권유불원투자자는 금융취약자로 분류되어 투자권유가 불가합니다."
          }
          this.fn_AlertPopup(0, t_popupObj)

          // 설문지 값 셋팅
          this.fn_quest_value_set()

          // 유효기간 설정여부
          this.lv_qstn_expd_yys.value = ''
          this.lv_qstn_expd_yys.disabled = true

          // 선택항목 비활성화
          this.lv_isList = true

          // 고객투자성향결과내역조회(수익증권) 결과 초기화 추가함
          this.lv_selectData = ''

        } else {

          // 설문지 값 셋팅
          this.fn_quest_value_set()

          // 유효기간 설정여부
          this.lv_qstn_expd_yys.disabled = false

          // 선택항목 활성화
          this.lv_isList = false

        }
      },

      /******************************************************************************
       * Function명 : fn_Qstn14_sel_yes, fn_Qstn14_sel_no
       * 설명       : 14번 항목 선택결과
       ******************************************************************************/
      fn_Qstn14_sel_yes () {
        console.log('fn_Qstn14_sel_yes.....')
        this.lv_qstn14_sel.value = '1'
      },
      fn_Qstn14_sel_no () {
        console.log('fn_Qstn14_sel_no.....')
        this.lv_qstn14_sel.value = '2'
      },      

      /******************************************************************************
       * Function명 : fn_TstRctstQstnSubmit, fn_TstRctstQstnSubmitResult
       * 설명       : 설문등록 (신탁정보로 설문등록)
       ******************************************************************************/
      async fn_TstRctstQstnSubmit () {
        console.log('fn_TstRctstQstnSubmit.....')

        // 필수값 체크?
        // if( !this.fn_ValidateForm() ) {
        //   return
        // }
        
        // 고객투자성향결과내역조회(신탁) 데이터 매핑
        Object.assign(this.lv_submit_data = this.lv_tstRctstData)

        this.lv_submit_data.rlnm_no_knd_cd    = this.lv_cob_rname_gb.value          // 실명번호종류

        // 실명번호
        let rlnm_cnf_no_encr = this.lv_rlnmData.rlnm_cnf_no_encr
        //let rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2
        this.lv_submit_data.rlnm_cnf_no_encr  = FSCommUtil.gfn_isNull_replace(rlnm_cnf_no_encr, '') // 실명번호

        if( !FSCommUtil.gfn_isNull(this.lv_ed_rname_nm.value) ) {
          this.lv_submit_data.fnd_cust_nm     = this.lv_ed_rname_nm.value           // 성명
        }

        // 실명번호로 조회했을 경우
        if( this.lv_custSearchType == '0' && !FSCommUtil.gfn_isNull(this.lv_rlnmData.bfcer_cust_no) ) {
          this.lv_submit_data.bfcer_cust_no   = this.lv_rlnmData.bfcer_cust_no      // 고객번호
        }

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570166_S'
        this.eaiCommObj.params = this.lv_submit_data

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_TstRctstQstnSubmitResult)
      },

      fn_TstRctstQstnSubmitResult (pResultData) {
        console.log('fn_TstRctstQstnSubmitResult.....')

        let t_data = pResultData.data
        console.log(t_data)

        if( FSCommUtil.gfn_isNull(t_data.error_msg) ) {
          let t_popupObj = {
            content: "최종 등록된 내용으로 조회합니다."
          }
          this.fn_AlertPopup(0, t_popupObj)

          // 설문등록 데이터 초기화
          this.fn_ClearSubmitData()

          // 고객투자성향결과내역조회(수익증권)
          this.fn_SelectDataSearch()

          // 보고서출력
			    // this.fn_ReportData('Y')

        }
      },


      /******************************************************************************
       * Function명 : fn_QstnSubmit, fn_QstnSubmitResult
       * 설명       : 설문등록 (수익증권)
       ******************************************************************************/
      async fn_QstnSubmit () {
        console.log('fn_QstnSubmit.....')

        let t_popupObj = {}

        // 투자권유 희망여부 : '일반투자자'인 경우 '투자권유불원'인 경우 등록불가. 
        if( this.lv_tlgm_ivt_peson_yn.value == 'N' && this.lv_ivt_cnvs_hop_yn.value == 'N' ) {
          let t_popupObj = {
            content: "투자권유불원투자자는 금융취약자로 분류되어 투자권유가 불가합니다."
          }
          this.fn_AlertPopup(0, t_popupObj)

          // 버튼 컨트롤
          this.lv_proc_gb = ''
          this.fn_BtnController()

          return
        }

        // 필수값 체크
        if( !this.fn_ValidateForm() ) {
          return
        }

        // 설문등록 데이터 매핑
        await this.fn_SetSubmitData()
        // console.log(this.lv_submit_data)

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570164_S'
        this.eaiCommObj.params = this.lv_submit_data

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_QstnSubmitResult)
      },

      fn_QstnSubmitResult (pResultData) {
        console.log('fn_QstnSubmitResult.....')

        let t_data = pResultData.data
        this.lv_submit_result = t_data
        console.log(t_data)

        if( FSCommUtil.gfn_isNull(t_data.error_msg) ) {

          this.lv_proc_gb = ''

          // 설문등록 데이터 초기화
          this.fn_ClearSubmitData()

          // 고객투자성향결과내역조회(수익증권)
          this.fn_SelectDataSearch()

          // 전자서식 호출
          // this.fn_ReportPrint()

        } else {
          let t_popupObj = {content: FSMessageUtil.commMessage([])[t_data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)
        }

      },


      /***************************************************************************************
       * Function명 : fn_ValidateForm
       * 설명       : 등록시 필수값 체크
      *****************************************************************************************/
      fn_ValidateForm() {
        console.log('fn_ValidateForm.....')
        let lv_vm = this

        let t_return = true

        // gfn_dataSet 으로 생성된 객체만 추가
        let t_vaildList1 = [
          this.lv_tlgm_ivt_peson_yn,    // 전문투자자여부
          this.lv_ivt_cnvs_hop_yn,      // 투자권유희망여부
          this.lv_qstn_expd_yys,        // 유효기간 설정여부
        ]

        if( FSCommUtil.gfn_isValidList(this, t_vaildList1, t_return) ) {
          document.querySelector('.mo-page__contents-wrapper').scrollTop = 0
          t_return = false
        }

        // 고객정보제공일 경우 (미제공일 경우는 체크할 필요가 없음)
        if( this.lv_ivt_cnvs_hop_yn.value == 'Y' ) {
	    
          // 재진단사유
          if( this.lv_selectData.tday_dgns_cnt > 0 ) {
            let t_vaildList2 = [
              this.lv_rad_reqstn_rsn,
            ]

            if( FSCommUtil.gfn_isValidList(this, t_vaildList2, t_return) ) {
              document.querySelector('.mo-page__contents-wrapper').scrollTop = 0
              t_return = false
            }
          }

          // 설문항목
          let t_vaildList3 = []
          for(let i=1; i<15; i++) {
            t_vaildList3.push(this['lv_qstn' + i + '_sel'])
          }
          console.log('t_vaildList3>>>>', t_vaildList3)
          console.log('t_vaildList3.refs1>>>>', this.$refs[t_vaildList3[0].ref])
          if( FSCommUtil.gfn_isValidList(this, t_vaildList3, t_return) ) {            
            //설문 미입력 항목 화면 이동
            for(let i=0; i<14; i++) {
              if(FSCommUtil.gfn_isNull(t_vaildList3[i].value)) {
                FSCommUtil.gfn_focus(this.$refs['lv_qstn' + (i+1)])
                break
              }           
            }
            t_return = false
          }

        }
        return t_return
      },


      /******************************************************************************
       * Function명 : fn_SetSubmitData
       * 설명       : 설문등록 데이터 매핑
       ******************************************************************************/
      async fn_SetSubmitData (type) {
        console.log('fn_SetSubmitData....')

        this.lv_submit_data.rlnm_no_knd_cd    = this.lv_cob_rname_gb.value          // 실명번호종류

        if( this.lv_custSearchType == '0' ) {
          // 실명번호로 조회했을 경우
          this.lv_submit_data.fnd_cust_nm       = this.lv_ed_rname_nm.value         // 성명
          this.lv_submit_data.bfcer_cust_no     = this.lv_rlnmData.bfcer_cust_no    // 고객번호
          this.lv_submit_data.rlnm_cnf_no_encr  = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2 // 실명번호

        } else {
          // 고객번호로 조회했을 경우
          this.lv_submit_data.fnd_cust_nm       = this.lv_ed_cust_nm.value          // 성명
          this.lv_submit_data.bfcer_cust_no       = this.lv_rlnmData.bfcer_cust_no         // 고객번호
          this.lv_submit_data.rlnm_cnf_no_encr  = this.lv_rlnmData.rlnm_cnf_no_encr // 실명번호
        }

        this.lv_submit_data.tlgm_ivt_peson_yn   = this.lv_tlgm_ivt_peson_yn.value   // 전문투자자여부
        this.lv_submit_data.ivt_cnvs_hop_yn     = this.lv_ivt_cnvs_hop_yn.value     // 투자권유희망여부
        this.lv_submit_data.qstn_expd_yys       = this.lv_qstn_expd_yys.value       // 유효기간설정여부
        this.lv_submit_data.drvpr_ivt_expr_mms  = ''                                // 파생상품투자경험개월수

        // 유효기간설정여부 값 없을경우 0 으로 세팅
        if( FSCommUtil.gfn_isNull(this.lv_qstn_expd_yys.value) ) {
          this.lv_submit_data.qstn_expd_yys = 0
        }

        // //2022-07-19_정서경_ASR220600021_투자자성향분석 관련 기능 및 서식 개정에 따른 조정 : 재진단 사유 추가 (당일 2,3회차시에만)
        this.lv_submit_data.thday_reqstn_rsn_cd = this.lv_rad_reqstn_rsn.value      // 재진단사유
        this.rpt_rad_reqstn_rsn = this.lv_submit_data.thday_reqstn_rsn_cd           // 재진단사유 리포트용

        // 선택항목
        this.lv_submit_data.inqry_resp_rslt_val_01 = this.lv_qstn1_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_02 = this.lv_qstn2_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_03 = this.lv_qstn3_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_04 = this.lv_qstn4_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_05 = this.lv_qstn5_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_06 = this.lv_qstn6_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_07 = this.lv_qstn7_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_08 = this.lv_qstn8_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_09 = this.lv_qstn9_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_10 = this.lv_qstn10_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_11 = this.lv_qstn11_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_12 = this.lv_qstn12_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_13 = this.lv_qstn13_sel.value
        this.lv_submit_data.inqry_resp_rslt_val_14 = this.lv_qstn14_sel.value

        // 전문투자자 시작/종료일자
        this.lv_submit_data.tlgm_ivt_peson_str_ymd = this.lv_pro_invest_fromTo.value[0].replace(/-/g, '')
        this.lv_submit_data.tlgm_ivt_peson_end_ymd = this.lv_pro_invest_fromTo.value[1].replace(/-/g, '')
          
        // this.ds_cs7005.setColumn(0, "sup_id", application.gv_adm_eno);
        // this.ds_cs7005.setColumn(0, "aprv_sno", application.gv_adm_seq_no);

      },


      /******************************************************************************
       * Function명 : fn_ClearSubmitData
       * 설명       : 설문등록 데이터 초기화
       ******************************************************************************/
      fn_ClearSubmitData () {
        console.log('fn_ClearSubmitData....')

        this.lv_submit_data = {
          rlnm_no_knd_cd: '',
          rlnm_cnf_no_encr: '',
          ivt_prpns_reg_sno: '',
          bfcer_cust_no: '',
          fnd_cust_nm: '',
          inqry_resp_rslt_val_01: '',
          inqry_resp_rslt_val_02: '',
          inqry_resp_rslt_val_03: '',
          inqry_resp_rslt_val_04: '',
          inqry_resp_rslt_val_05: '',
          inqry_resp_rslt_val_06: '',
          inqry_resp_rslt_val_07: '',
          inqry_resp_rslt_val_08: '',
          inqry_resp_rslt_val_09: '',
          inqry_resp_rslt_val_10: '',
          inqry_resp_rslt_val_11: '',
          inqry_resp_rslt_val_12: '',
          inqry_resp_rslt_val_13: '',
          inqry_resp_rslt_val_14: '',
          inqry_rslt_score: '',
          ivt_prpns_anly_grd_cd: '',
          drvpr_ivt_expr_mms: '',
          ivt_cnvs_hop_yn: '',
          qstn_expd_yys: '',
          expd_stnd_ymd: '',
          expd_exp_ymd: '',
          qstn_reg_systm_knd_sc_cd: '',
          tlgm_ivt_peson_yn: '',
          tlgm_ivt_peson_str_ymd: '',
          tlgm_ivt_peson_end_ymd: '',
          ivt_prpns_lst_chg_dtm: '',
          report_rlnm_cnf_no_encr: '',
          tday_dgns_cnt: '',
          sup_id: '',
          aprv_sno: '',
          thday_reqstn_rsn_cd: '',
        }
      },


      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       ******************************************************************************/
      async fn_ReportPrint () {
        console.log('fn_ReportPrint....')
        let lv_vm = this
        let formList = []
        let FS000012 = {formId: 'FS000012'}
        let FS000013 = {formId: 'FS000013'}

        let searchData = this.lv_selectData
        searchData.rlnm_cnf_no_encr = this.lv_rlnmData.rlnm_cnf_no_encr
        searchData.thday_reqstn_rsn_cd = this.rpt_rad_reqstn_rsn
        console.log(searchData)

        // 커버발행 파라미터 저장
        this.$bizUtil.fsUtils.saveCvrPblParam(this, {bfcer_cust_no: this.lv_rlnmData.rlnm_cnf_no_encr})
        
        FS000012.params = {
          reportParam: JSON.stringify(searchData),
          reportUrl: 'reportCS700500_Info',
        }
        formList.push(FS000012)


        // console.log(this.lv_basInfo.busyDate)
        // 당일설문일 경우
        if( this.lv_basInfo.busyDate == this.lv_selectData.expd_stnd_ymd ) { 

          // 전문금융소비자가 아니고, 투자권유희망여부 고객정보제공일 경우        
          if( this.lv_selectData.tlgm_ivt_peson_yn == 'N' && this.lv_selectData.ivt_cnvs_hop_yn == 'Y' ) {
            FS000013.params = {
              reportParam: JSON.stringify(searchData),
              reportUrl: 'reportCS701000_Info',
            }
            formList.push(FS000013)
          }
        }
        
        //프로세스일 경우
        if ( this.lv_isProcess ) { 
          FSInfoUtil.commReportInfo (this, formList, this.fn_GoNextPage, false, 'MSPFS110M');
        } else {  // 개별일 경우
          this.getStore('fsStore').dispatch('IS_LAST_START')
          let endPopup = function() {
                  lv_vm.fn_AlertPopup(0, {content: '고객투자자성향분석이 완료되었습니다.'})
          }
          FSInfoUtil.commReportInfo(this, formList, endPopup)
        }
      },


      /******************************************************************************
       * Function명 : fn_GoNextPage
       * 설명       : 고객성향분석결과 페이지로 이동
       ******************************************************************************/
      fn_GoNextPage(nextPage) {
        this.$router.push({
          name: FSCommUtil.gfn_isNull(nextPage)  ? 'MSPFS111M' : nextPage, 
          params: {
            cob_rname_gb: this.lv_rlnmData.rlnm_no_knd_cd, 
            ed_rname_no: [FSCommUtil.gfn_substr(this.lv_rlnmData.rlnm_cnf_no_encr, 0, 6), FSCommUtil.gfn_substr(this.lv_rlnmData.rlnm_cnf_no_encr, 6, 7)],
            bfcer_cust_no: this.lv_route_cust_no,
            search_type: this.lv_custSearchType,
          }
        })
      },

      fn_clear(param) {
        switch(param) {
          case 'ivtCnvs':
            this.lv_ivt_cnvs_hop_yn.error = false
            break
          case 'qstnExpd':
            this.lv_qstn_expd_yys.error = false
            break
          case 'radReqstn':
            this.lv_rad_reqstn_rsn.error = false
            break
          case 'qstn1':
            this.lv_qstn1_sel.error = false
            break
          case 'qstn2':
            this.lv_qstn2_sel.error = false
            break
          case 'qstn3':
            this.lv_qstn3_sel.error = false
            break
          case 'qstn4':
            this.lv_qstn4_sel.error = false
            break
          case 'qstn5':
            this.lv_qstn5_sel.error = false
            break
          case 'qstn6':
            this.lv_qstn6_sel.error = false
            break
          case 'qstn7':
            this.lv_qstn7_sel.error = false
            break
          case 'qstn8':
            this.lv_qstn8_sel.error = false
            break
          case 'qstn9':
            this.lv_qstn9_sel.error = false
            break
          case 'qstn10':
            this.lv_qstn10_sel.error = false
            break
          case 'qstn11':
            this.lv_qstn11_sel.error = false
            break
          case 'qstn12':
            this.lv_qstn12_sel.error = false
            break
          case 'qstn13':
            this.lv_qstn13_sel.error = false
            break
          case 'qstn14':
            this.lv_qstn14_sel.error = false
            break
          
        }
      },
    }
  }
</script>
<style scoped>
</style>