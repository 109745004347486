/**
 * <PRE>
 * System Name : 사랑On NEXT 시스템(TSS2-MSP)
 * Business Name : 수익증권
 * Class Name : FSCodeUtil.js
 * Description : 수익증권 공통 코드 유틸
 * </PRE>
 */

import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import fsConstants from '@/config/constants/fsConstants'

const FSCodeUtil = {}


/************************************************************************************************
 * Function명    : commCodeList
 * 설명          : 공통코드
 * pData         : 조회 조건
 * resultHandler : 응답 메소드
 ************************************************************************************************/
 FSCodeUtil.commCodeList = function (pContext, pData, resultHandler, errorHandler) {
  const context       = !pContext ? this : pContext
  const errorNoData   = '처리 데이터가 없습니다.'


  if (pData) {
    let t_eaiCommObj  = FSCommUtil.gfn_eaiCommObj()

    let functionName = 'U' + 'C391_F10570266_S'.split('_')[1]
    let interfaceInfo = fsConstants.INTERFACE_DICTIONARY[functionName]
    let eaiId = fsConstants.INTERFACE_SYSTEM_PREFIX + interfaceInfo.serviceId + fsConstants.INTERFACE_SYSTEM_SUFFIX
    
    let commHeader = {
      eaiId: eaiId,
      fahrTrnId: '',
      requestSystemCode: 'F1391',
      reqMthdNm: interfaceInfo.reqMthNm,
      targetSystemCode: interfaceInfo.appId,
      resVONm: interfaceInfo.resVONm,
      reqVONm: interfaceInfo.reqVONm,
      useCompress: true
      // useCompress: false
    }

    t_eaiCommObj.lv_vm = context
    t_eaiCommObj.auth = 'S'
    t_eaiCommObj.trnstId = 'txTSSFS01P1'    

    if( pData.length == 0 ) {
      console.log('[공통코드] 조회 조건이 없습니다.')
      return
    }

    window.vue.post(context, {'TUKEMK' : pData}, t_eaiCommObj.trnstId, t_eaiCommObj.auth, commHeader)
      .then(function (response) {
        let resMsgComm = response.msgComm

        if (response && response.body) {
          let resData = response.body

          if (resData !== null) {
            let t_params = {
              data    : resData,
              msgComm : resMsgComm,
            }
            
            if(resultHandler) {
              let t_codeObj  = {}

              if( FSCommUtil.gfn_length(t_params.data.tukemk) > 0 ) {
                t_params.data.tukemk.forEach((item1) => {

                  let t_codeList = []
                  item1.tukemk.forEach((item2) => {
                    t_codeList.push({ value: item2.cdDtl, text: item2.cnm, cdesc1: item2.cdesc1 })
                  })

                  let objName = 'code' + item1.cId.replace('C', '')
                  t_codeObj[objName] = t_codeList
                })
              }

              resultHandler.call(context, t_codeObj)
            } // end if

          } else {
            FSCodeUtil.errorHandlerComm(context, {msgCd: 'NODATA', msgDesc: errorNoData})
          } // end else if

        } else {
          FSCodeUtil.errorHandlerComm(context, resMsgComm)
        } // end else if

      }).catch(function (error) {
        FSCodeUtil.errorHandlerComm(context, {msgCd: 'ERROR', msgDesc: error.message})
      })    

  } else {

    console.log('[공통코드] 조회 조건이 없습니다.')
  }
}

/************************************************************************************************
 * Function명  : errorHandlerComm
 * 설명        : 에러처리
 ************************************************************************************************/
 FSCodeUtil.errorHandlerComm = function(context, event, errorHandler) {
  if(errorHandler) {
    // 에러 별도처리

  } else {
    context.getStore('confirm').dispatch('ADD', event.msgDesc)
  } // end else if
}



/************************************************************************************************
 * Function명  : commCode
 * 설명        : 공통코드(호출 PO 작업 미진행으로 임시 하드코딩...)
 * @param pParams       : 조회 조건
 * @param resultHandler : 응답 메소드
 * @param errorHandler  : 오류 이벤트
 ************************************************************************************************/
 FSCodeUtil.commCode = function (pParams, resultHandler, errorHandler) {
  const context       = !pParams.lv_vm ? this : pParams.lv_vm
  const data          = pParams.params
  const trnstId       = pParams.trnstId
  const auth          = pParams.auth
  const commHeaderVO  = pParams.commHeaderVO
  let commCode        = {}

  let errorFail       = '처리 중 오류가 발생했습니다.'
  let errorNoData     = '처리 데이터가 없습니다.'
  // let errorE001Data = '[E001] 통신상태가 원활하지 않아 서비스가 지연되고 있습니다.\n잠시 후 다시 시도하세요'

  if (pParams) {
    try {

      // 공통코드 호출 PO가 없어 임시 하드코딩...
      commCode = {
        code211: [
          {value: '01', text: '주민등록번호'},
          // {value: '02', text: '의료보험증관리번호'},
          // {value: '03', text: '재외국민등록번호'},
          {value: '04', text: '외국인등록번호'},
          // {value: '05', text: '국내거소신고번호'},
          // {value: '06', text: '여권번호등'},
          // {value: '07', text: '사업자등록번호'},
          // {value: '08', text: '투자등록번호'},
          // {value: '09', text: '고유번호'},
        ],

        code981: [
          {value: 'KR', text: '한국'},
          {value: 'JP', text: '일본'},
          {value: 'FR', text: '프랑스'},
          {value: 'US', text: '미국'},
        ],

        code213: [
          {value: '01', text: '물품대금결제'},
          {value: '02', text: '상속/증여성 거래'},
          {value: '03', text: '부채상환'},
          {value: '04', text: '기타'},
        ],

        code214: [
          {value: '01', text: '급여 및 생활비'},
          {value: '02', text: '사업상거래'},
          {value: '03', text: '저축 및 투자'},
          {value: '04', text: '기타'},
        ],

        code371: [
          {value: '01', text: '의료복지'},
          {value: '02', text: '교육'},
          {value: '03', text: '공공기관'},
          {value: '04', text: '기타'},
        ],

        code215: [
          {value: '1', text: '자택'},
          {value: '2', text: '직장'},
        ],

        code216: [
          {value: '01', text: '영리법인'},
          {value: '02', text: '비영리법인'},
          {value: '03', text: '기타단체'},
          {value: '04', text: '외국법인'},
        ],

        code217: [
          {value: '01', text: '급여소득자'},
          {value: '02', text: '사업소득자'},
          {value: '03', text: '전문직'},
          {value: '04', text: '연금생활자'},
          {value: '05', text: '주부'},
          {value: '06', text: '학생'},
          {value: '07', text: '기타'},
        ],

        code218: [
          {value: '001', text: '인바운드CALL'},
          {value: '002', text: '아웃바운드CALL'},
          {value: '003', text: '문자메세지(SMS)'},
          {value: '004', text: '내방상담'},
          // {value: '005', text: '아웃바운드'},
          {value: '006', text: '기타'},
        ],

        code219: [
          {value: '01', text: '수익률안내'},
          {value: '02', text: '상품제안'},
          {value: '03', text: '신규계좌개설'},
          {value: '04', text: '매입/환매상담'},
          {value: '05', text: '고객정보변경'},
          {value: '06', text: '교육참석안내'},
          {value: '07', text: '세미나안내'},
          {value: '08', text: '식사약속'},
          {value: '09', text: 'ELF터치'},
          {value: '10', text: '기타'},
          {value: '11', text: 'VOC'},
          // {value: '12', text: '방문약속'},
          // {value: '13', text: '방문결과'},
        ],

        code407: [
          {value: '00', text: '해당없음'},
          {value: '01', text: '유가증권시장'},
          {value: '02', text: '코스닥시장'},
        ],

        code408: [
          {value: '00', text: '해당없음'},
          {value: '03', text: '뉴욕증권거래소'},
          {value: '04', text: '나스닥'},
          {value: '05', text: '런던증권거래소'},
          {value: '06', text: '홍콩증권거래소'},
          {value: '07', text: '기타'},
        ],
       
        code410: [
          {value: '01', text: '본인'},
          {value: '02', text: '배우자'},
          {value: '03', text: '직계가족'},
          {value: '04', text: '기타'},
          {value: '05', text: '자녀'},
          {value: '06', text: '부모'},
          {value: '07', text: '법정대리인'},
          {value: '08', text: 'HR'},
          {value: '09', text: '기타HR'},
          {value: '10', text: '형제자매'},
          {value: '11', text: '상사'},
          {value: '12', text: '친척'},
          {value: '13', text: '동료/친구'},
        ],

        code370: [
          {value: '010', text: '010'},
          {value: '011', text: '011'},
          {value: '016', text: '016'},
          {value: '017', text: '017'},
          {value: '018', text: '018'},
          {value: '019', text: '019'},
        ],

        code206: [
          {value: '111', text: '개인(주민등록번호)'},
          // {value: '112', text: '개인(의료보호증관리번호)'},
          // {value: '121', text: '개인(외국여권번호)'},
          // {value: '122', text: '개인(재외국민등록번호)'},
          {value: '131', text: '개인(외국인등록번호)'},
          // {value: '141', text: '개인(국내거소신고번호)'},
          // {value: '211', text: '법인(사업자등록번호81:86)'},
          // {value: '311', text: '법인(고유번호80,89)'},
          // {value: '331', text: '개인(대표자주민등록번호)'},
          // {value: '411', text: '법인(투자등록번호)'},
        ],

        code210: [
          {value: '0', text: '일반'},
          {value: '1', text: '장애인'},
          {value: '2', text: '국가유공상이자'},
          {value: '3', text: '독립유공자'},
          {value: '4', text: '생활보호대상자'},
          {value: '5', text: '고엽제휴유의중환자'},
          {value: '6', text: '5.18민주화운동부상자'},
        ],

        code221: [
          {value: '15', text: '스마트'},          
          {value: '11', text: '자택'},
          {value: '12', text: '직장'},
          {value: '14', text: 'E-Mail'},
          {value: '19', text: '사절'},
        ],

        code223: [
          {value: '14', text: 'E-Mail'},
          {value: '19', text: '사절'},
        ],

        code9200: [
          {value: '02', text: '경기'},
          {value: '09', text: '서울'},
        ],

        code9202: [
          {value: '0201', text: '가평군'},
          {value: '0202', text: '고양시 덕양구'},
          {value: '0203', text: '고양시 일산동구'},
          {value: '0204', text: '고양시 일산서구'},
          {value: '0205', text: '과천시'},
          {value: '0206', text: '광명시'},
          {value: '0207', text: '광주시'},
          {value: '0208', text: '구리시'},
          {value: '0209', text: '군포시'},
          // 중략
        ],

        code9209: [
          {value: '0901', text: '강남구'},
          {value: '0902', text: '강동구'},
          {value: '0903', text: '강북구'},
          {value: '0904', text: '강서구'},
          {value: '0905', text: '관악구'},
          {value: '0906', text: '광진구'},
          {value: '0907', text: '구로구'},
          {value: '0908', text: '금천구'},
          {value: '0909', text: '노원구'},
          {value: '0910', text: '도봉구'},
          {value: '0911', text: '동대문구'},
          {value: '0912', text: '동작구'},
          {value: '0913', text: '마포구'},
          {value: '0914', text: '서대문구'},
          {value: '0915', text: '서초구'},
          {value: '0916', text: '성동구'},
          {value: '0917', text: '성북구'},
          {value: '0918', text: '송파구'},
          {value: '0919', text: '양천구'},
          {value: '0920', text: '영등포구'},
          {value: '0921', text: '용산구'},
          {value: '0922', text: '은평구'},
          {value: '0923', text: '종로구'},
          {value: '0924', text: '중구'},
          {value: '0925', text: '중랑구'},
        ],


        code420: [
          {value: '01', text: '배우자'},
          {value: '02', text: '직계비속'},
          {value: '03', text: '직계존속'},
          {value: '04', text: '법인대표'},
          {value: '05', text: '종업원'},
        ],

        code1050: [
          {value: '01', text: '투자자 본인 단독거래하기를 원함'},
          {value: '02', text: '가족에 투자사실을 알리기 원치않음'},
          {value: '03', text: '본인이 충분히 이해가능한 상품에 해당됨'},
          {value: '99', text: '기타사유'},
        ],

        code322: [
          {value: '11', text: '전액환매'},
          {value: '12', text: '좌수환매'},
          {value: '13', text: '정액환매'},
          // {value: '16', text: '특정건좌수환매'},
          // {value: '18', text: '매입일자별환매(재투자포함)'},
        ],

        code303: [
          {value: '11', text: '재투자(수익증권)'},
          {value: '12', text: '예탁금(이용료)'},
          {value: '13', text: '예탁금(현금)'},
          {value: '16', text: '매입(이동입고)'},
          {value: '19', text: '매입(재투자포함)'},
        ],

        code301: [
          {value: '00', text: '전업무'},
          {value: '11', text: '매입/입금[1]'},
          {value: '12', text: '입금(매입)취소'},
          {value: '21', text: '출금/환매[1]'},
          {value: '22', text: '출금(환매)취소'},          
          {value: '31', text: '전환[1]'},
          {value: '32', text: '전환취소'},
          {value: '35', text: '연금계좌이체'},
          {value: '36', text: '연금계좌이체취소'},
          {value: '41', text: '상환'},
          {value: '51', text: '배당'},
          {value: '52', text: '배당취소'},
          {value: '61', text: '출고(계좌대체)'},
          {value: '62', text: '입고(계좌대체)'},
        ],

        code991 : [
          {value: '', text: '전체'},
          {value: '101', text: '한국투신운용'},
          {value: '102', text: '하나USB자산운용'},
          {value: '103', text: '한국투자리얼에셋운용'},
          {value: '104', text: '브이아이자산운용'},
          {value: '105', text: '삼성자산운용'},
          {value: '106', text: '흥국자산운용'},
          {value: '107', text: '우리자산운용'},
          {value: '108', text: '하이자산운용'},
          {value: '109', text: 'BNK자산운용'},
          {value: '110', text: '현대자산운용'},
          {value: '203', text: '대신자산운용'},
          {value: '204', text: '동원투신운용'},
          {value: '206', text: '키움투자자산운용'},
          {value: '207', text: '교보악사자산운용'},
          {value: '208', text: '교보증권(전문사모)'},
          {value: '209', text: '신영자산운용'},
          {value: '210', text: '신한자산운용'},
          {value: '212', text: '맥쿼리투신운용'},
          {value: '213', text: '한화자산운용'},
          {value: '214', text: '유진자산운용'},
          {value: '215', text: 'SK투신운용'},
          {value: '216', text: 'DB자산운용'},
          {value: '217', text: '프랭클린텐플턴투신운용'},
          {value: '219', text: 'SH자산운용'},
          {value: '221', text: '우리투신운용'},
          {value: '222', text: '외환코메르쯔투신운용'},
          {value: '223', text: 'KB자산운용'},
          {value: '224', text: '골든브릿지자산운용'},
          {value: '225', text: '유경PSG자산운용'},
          {value: '226', text: 'HDC자산운용'},
          {value: '228', text: '우리글로벌자산운용'},
          {value: '229', text: '이스트스프링자산운용'},
          {value: '230', text: '슈로더투신운용'},
          {value: '231', text: '디더블유에스자산운용'},
          {value: '232', text: 'NH-Amundi자산운용'},
          {value: '233', text: 'IBK자산운용'},
          {value: '234', text: '다비하나인프라펀드자산운용'},
          {value: '235', text: '다올부동산자산운용'},
          {value: '236', text: '디에스자산운용'},
          {value: '237', text: '파인밸류자산운용'},
          {value: '238', text: '브레인자산운용'},
          {value: '239', text: '삼성액티브자산운용'},
          {value: '240', text: '삼성해지자산운용'},
          {value: '241', text: '멀티에셋자산운용'},
          {value: '242', text: '안다자산운용'},
          {value: '243', text: '이지스자산운용'},
          {value: '244', text: '타임폴리오자산운용'},
          {value: '245', text: 'JB자산운용'},
          {value: '246', text: '포트코리아자산운용'},
          {value: '247', text: '코람코자산운용'},
          {value: '248', text: '씨스퀘어자산운용'},
          {value: '249', text: '플랫폼파트너스자산운용'},
          {value: '250', text: '씨앗자산운용'},
          {value: '251', text: '퍼시픽브릿지자산운용'},
          {value: '252', text: '베스타스자산운용'},
          {value: '253', text: '알펜루트자산운용'},
          {value: '254', text: '쿼드자산운용'},
          {value: '255', text: '캡스톤자산운용'},
          {value: '256', text: '푸른파트너스자산운용'},
          {value: '301', text: '미래에셋자산운용'},
          {value: '302', text: '베어링자산운용'},
          {value: '303', text: '마이다스에셋자산운용'},
          {value: '304', text: '미래에셋맵스자산운용'},
          {value: '305', text: 'ING자산운용'},
          {value: '306', text: '다올자산운용'},
          {value: '307', text: '유리자산운용'},
          {value: '310', text: '마이에셋자산운용'},
          {value: '311', text: '플러스자산운용'},
          {value: '312', text: '맥쿼리IMM자산운용'},
          {value: '313', text: '와이즈에셋자산운용'},
          {value: '314', text: '칸서스자산운용'},
          {value: '315', text: '피닉스자산운용'},
          {value: '316', text: '피델리티자산운용'},
          {value: '317', text: '한국밸류자산운용'},
          {value: '318', text: 'JP모간자산운용'},
          {value: '321', text: '알파에셋자산운용'},
          {value: '363', text: '트러스톤자산운용'},
          {value: '451', text: '한국인프라자산운용'},
          {value: '452', text: '맥쿼리자산운용'},
          {value: '901', text: '푸르덴셜자산운용'},
          {value: '902', text: '산은자산운용'},
          {value: '903', text: '블랙록자산운용'},
          {value: '905', text: '에셋플러스자산운용'},
          {value: '907', text: '메리츠자산운용'},
          {value: '908', text: '현대인베스트먼트자산운용'},
          {value: '909', text: '얼라이언스번스틴자산운용'},
          {value: '910', text: '라자드코리아자산운용'},
        ],

        code107: [
          {value: 'ALL', text: '전체'},
          {value: '1', text: 'MMF'},
          {value: '2', text: '채권형'},
          {value: '3', text: '채권혼합형'},
          {value: '4', text: '주식혼합형'},
          {value: '5', text: '주식형'},
          {value: '6', text: '파생상품'},
          {value: '7', text: '부동산'},
          {value: '8', text: '재간접'},
          {value: '9', text: '특별자산'},
          {value: 'A', text: '실물자산'},
          {value: 'B', text: '혼합자산'},
          {value: 'F5', text: '주식형(해외투자)'},
          {value: 'F6', text: '재간접(해외투자)'},
        ],
      }

      resultHandler.call(context, commCode)

    } catch (error) {
      FSCodeUtil.errorHandlerComm(context, {msgCd: 'ERROR', msgDesc: errorFail}, errorHandler)
    }
  }
}


/************************************************************************************************
 * Function명  : errorHandlerComm
 * 설명        : 에러처리
 ************************************************************************************************/
FSCodeUtil.errorHandlerComm = function(context, event, errorHandler) {
  if(errorHandler) {
    // 에러 별도처리

  } else {
    context.getStore('confirm').dispatch('ADD', event.msgDesc)
  } // end else if
}

export default FSCodeUtil
// eslint-disable-next-line
